<script setup lang="ts">
import { cva } from 'class-variance-authority';
import 'vue-skeletor/dist/vue-skeletor.css';
import { Skeletor } from 'vue-skeletor';

type PropertyRowCellProps = {
  property: Domain.Properties.DataObjects.PropertyData;
  size?: 'default' | 'large';
};

const props = withDefaults(defineProps<PropertyRowCellProps>(), {
  size: 'default',
});

const imageLoaded = ref<boolean>(false);
const imageError = ref<boolean>(false);

const addressLineOne = computed<string | undefined>(
  () => props.property?.shortAddress,
);
const addressLineTwo = computed<string>(
  () => props.property?.area?.name + ', ' + props.property?.postcode,
);

const skeletorSize = computed<{ width: number; height: number }>(() => {
  if (props.size === 'large') {
    return {
      width: 182,
      height: 116,
    };
  }

  return {
    width: 120,
    height: 75,
  };
});

const imageClasses = cva('object-position-center object-cover', {
  variants: {
    size: {
      default: 'h-[75px] w-[120px] rounded',
      large: 'h-[116px] w-[182px] rounded-md',
    },
  },
});
</script>

<template>
  <div class="flex gap-4">
    <div>
      <Skeletor
        v-if="!imageLoaded"
        role="status"
        :width="skeletorSize.width"
        :height="skeletorSize.height"
        class="mr-4 rounded-lg"
      />
      <img
        :class="[
          imageClasses({ size: props.size }),
          {
            hidden: !imageLoaded,
          },
        ]"
        :src="props.property.imageUrl"
        :alt="addressLineOne"
        @load="imageLoaded = true"
        @error="
          imageLoaded = true;
          imageError = true;
        "
      />
    </div>

    <div class="flex flex-1 flex-col">
      <span
        class="text-base font-normal leading-5 text-gray-900 dark:text-white md:leading-6"
        >{{ addressLineOne }}</span
      >
      <span class="mb-3 text-gray-500 dark:text-gray-300">{{
        addressLineTwo
      }}</span>

      <ul class="flex gap-4 text-sm text-gray-500">
        <li>
          <FontAwesomeIcon :icon="['fas', 'bed']" class="mr-1 text-gray-300" />
          {{ props.property.roomCount }}
        </li>
        <li>
          <FontAwesomeIcon :icon="['fas', 'bath']" class="mr-1 text-gray-300" />
          {{ props.property.bathrooms }}
        </li>
        <li v-if="props.property.isFeedProperty">
          <FontAwesomeIcon
            :icon="['fas', 'house-signal']"
            class="mr-1 text-gray-300"
          />
          Ref: {{ props.property.feedReference.feed_reference }}
          <pre class="hidden">{{ props.property.feedReference }}</pre>
        </li>
      </ul>
    </div>
  </div>
</template>
